import React from "react"
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Play as PlayIcon } from 'react-feather';

const responsive = 800;

const Container = styled.div`
  position: relative;
  width: ${props => props.size + 'rem'};
  height: ${props => props.size + 'rem'};
  min-width: ${props => props.size + 'rem'};
  min-height: ${props => props.size + 'rem'};

  @media only screen and (max-width: ${responsive}px) {
    width: ${props => (props.size * .66) + 'rem'};
    height: ${props => (props.size * .66) + 'rem'};
    min-width: ${props => (props.size * .66) + 'rem'};
    min-height: ${props => (props.size * .66) + 'rem'};
  }
`;

const Cover = styled.div`
  background: ${props => props.url ? css`url(${props.url})` : props.color};
  background-size: contain;
  background-position: center center;
  border-radius: .25rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: ${props => props.size + 'rem'};
  height: ${props => props.size + 'rem'};
  min-width: ${props => props.size + 'rem'};
  min-height: ${props => props.size + 'rem'};

  @media only screen and (max-width: ${responsive}px) {
    width: ${props => (props.size * .66) + 'rem'};
    height: ${props => (props.size * .66) + 'rem'};
    min-width: ${props => (props.size * .66) + 'rem'};
    min-height: ${props => (props.size * .66) + 'rem'};
  }
`;

const Offset = styled.div`
  position: absolute;
  bottom: ${props => (-props.offset * .35) + 'rem'};
  right: ${props => (-props.offset * .35) + 'rem'};
`;

const PlayButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 150ms;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;

    & * {
      transform: none;
    }
  }

  & * {
    transform: translateY(.5rem);
    transition: transform 150ms;
  }
`;

const PlayButton = styled.div`
  width: 5rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid white;
  box-shadow: 0.25rem 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, .15);
  border-radius: .3rem;
  background-color: rgba(0, 0, 0, .4);
`;

const AlbumCover = ({ imgUrl, stacked, size, href, ...props }) => {
  size = size ?? 15;
  const content = (
    <Container size={size} {...props}>
      {stacked ? (
        <>
          <Offset offset={2}>
            <Cover size={size} color="#8a8a8a" />
          </Offset>
          <Offset offset={1}>
            <Cover size={size} color="#A8A8A8" />
          </Offset>
          <Offset offset={0}>
            <Cover size={size} url={imgUrl} />
          </Offset>
        </>
      ) : (
        <Cover size={size} url={imgUrl} />
      )}
      {href ? (
        <PlayButtonContainer>
          <PlayButton>
            <PlayIcon style={{ color: 'white', fill: 'white', width: '1.125rem', height: '1.125rem'}} />
          </PlayButton>
        </PlayButtonContainer>
      ) : null}
    </Container>
  )

  if (href) {
    return (
      <a href={href} target="_blank" style={{}}>
        {content}
      </a>
    )
  };

  return content;
};

AlbumCover.propTypes = {
  imgUrl: PropTypes.string.isRequired
};

AlbumCover.defaultProps = {
};

export default AlbumCover;
