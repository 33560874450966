import React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"

// const Button = ({ children, ...props }) => (
//   <HeroButton {...props}>{children}</HeroButton>
// );

// Button.propTypes = {
// };

// Button.defaultProps = {
// };

const HeroButton = styled.button`
  word-wrap: break-word;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  box-shadow: none;
  outline: none;
  border: none;
  background: ${props => props.accent ? '#4b46a7' : '#555379'};
  color: white;
  border-radius: .25rem;
  padding: .5rem 1rem;
  text-transform: lowercase;
  transition: all 100ms;
  cursor: pointer;
  user-select: none;

  &:hover:not(:active) {
    background-color: ${props => props.accent ? '#736ed8' : '#6b6994'};
    transform: translate(-.2rem, -.2rem);
    box-shadow: .2rem .2rem rgba(0, 0, 0, .35);
  };

  &:hover::after {
    content: '';
    display: block;
    position: absolute;
    top: .2rem;
    width: 100%;
    height: 100%;
  }
`;

export default HeroButton;
