import React, { useState } from "react"
import styled, { css, createGlobalStyle } from 'styled-components';
import { Link } from "gatsby"
import { Menu as MenuIcon, X as MenuCloseIcon } from 'react-feather';

const links = [
  { text: 'Home', link: '/#' },
  { text: 'Showreel', link: '/#selected-works' },
  { text: 'Discography', link: '/#releases' },
  { text: 'About', link: '/about' },
  { text: 'Contact', link: '/about#contact' }
];
const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <DesktopMenu>
        {links.map(({ text, link }) => (
          <NavItem to={link} key={link}>{text}</NavItem>
        ))}
      </DesktopMenu>
      <MobileMenu>
        {menuOpen ? (
          <>
          <MenuCloseIcon style={{ margin: '1rem', width: '2rem', height: '2rem', position: 'fixed', zIndex: 100, cursor: 'pointer', userSelect: 'none' }}
            onClick={() => setMenuOpen(false)} />
          </>
        ) : (
          <MenuIcon style={{ margin: '1rem', width: '2rem', height: '2rem', position: 'absolute', cursor: 'pointer', userSelect: 'none' }}
            onClick={() => setMenuOpen(true)} />
        )}
        <MobileMenuPageWrapper active={menuOpen} onClick={() => setMenuOpen(false)} />
        <MobileMenuContent active={menuOpen}>
          {links.map(({ text, link }) => (
            <MobileNavItem to={link} key={link} onClick={() => setMenuOpen(false)}>{text}</MobileNavItem>
          ))}
        </MobileMenuContent>
        <MobileNoScroll active={menuOpen} />
      </MobileMenu>
    </>
  );
}

Nav.propTypes = {
};

Nav.defaultProps = {
};

const DesktopMenu = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 4rem;
`;

const MobileNavItem = styled(Link)`
  font-family: Sintony, Arial, Helvetica, sans-serif;
  padding: .5rem 0;
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: bold;
  color: black;

`;

const NavItem = styled(Link)`
  padding: 1rem;
  overflow: hidden;
  text-transform: lowercase;
  text-decoration: none;
  position: relative;
  color: black;
  transition: color 200ms;
  z-index: 0;
  user-select: none;

  &:hover {
    color: white;
  }

  &:hover:before {
    transform: none;
    opacity: 1;
  }

  &:before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    transition: transform 150ms;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(-100%);
    background-color: #555379;
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    opacity: 0;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

const MobileMenuPageWrapper = styled.div.attrs({ ariaHidden: true })`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98;
  ${props => props.active ? css`
      opacity: 1;
  ` : css`
      pointer-events: none;
      opacity: 0;
      display: none;
  `}
`;

const MobileMenuContent = styled.nav`
  position: fixed;
  height: 100%;
  width: 70%;
  min-width: 200px;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: white;
  transition: transform 150ms, opacity 100ms;
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;

  ${props => props.active ? css`
    opacity: 1;
    transform: none;
  ` : css`
    pointer-events: none;
    opacity: 0;
    /* display: none; */
    transform: translateX(-100%);
  `}
`;

const MobileNoScroll = createGlobalStyle`
  body {
    ${props => props.active ? css`
      overflow: hidden; 
      max-height: 100vh;
    ` : null}
  }
`;

export default Nav;
