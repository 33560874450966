/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle, css } from 'styled-components';
import FabricBg from '../images/fabric.png';
import Header from "./header"
import { SocialButtons } from "./hero";

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    font-family: Sintony, Arial, Helvetica, sans-serif;
    height: 100vh;
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }
`;

const Layout = ({ children, fabricBg, noSocial }) => {

  return (
    <>
      <GlobalStyle />
      <Main fabricBg={fabricBg}>
        {children}
        <FooterContainer light={fabricBg}>
          <Footer>
            <div style={{ textAlign: 'center' }}>
              <p><span style={{ color: '#ff4a4a', fontSize: '1.2rem', marginRight: '.45rem' }}>♥</span> from Dallas, TX</p>
              <p style={{ fontSize: '.8125rem' }}>© 2020</p>
            </div>
          </Footer>
        </FooterContainer>
      </Main>
    </>
  )
}

const Main = styled.main`
  position: relative;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  ${props => !props.fabricBg ? css`
    background-color: #FCFCFC;
  ` : css`
    background-repeat: repeat;
    background-size: 14rem;
    background: url("${FabricBg}"), #555379;
    background-blend-mode: multiply;
  `}
`;

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 10rem;
  margin-top: 2rem;
  padding: 5rem 0;
  color: ${props => props.light ? '#f1f1f1' : 'black'};
`;

const Footer = styled.div`
  width: 100%;
  max-width: 70rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export default Layout
