import React, { useState } from 'react';
import styled from 'styled-components';
import AlbumCover from './album-cover';
import Button from './button';
import { Link, navigate } from 'gatsby';
import { Smile, Play } from 'react-feather';
import BandcampIcon from '../images/bandcamp.svg';
// import YoutubeIcon from '../images/youtube.svg';
import TwitterIcon from '../images/twitter.svg';
import MailIcon from '../images/mail.svg';
import JanusOstArtwork from '../images/releases/janus-ost.png';

function getSoundcloudWidget() {
  const iframe = document.querySelector('iframe#soundcloud-embed');
  if (!iframe) {
    return null;
  }
  return window.SC.Widget(iframe);
}

const Hero = ({ children }) => {
  function playShowreel() {
    console.log("Play showreel");
    const widget = getSoundcloudWidget();
    if (widget) {
      widget.play();
    }
    navigate('/#selected-works');
  }

  return (
    <MainContainer>
      <HeroContainer>
        <HeroSlant />
        <HeroContent>
          <div style={{ filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.1))' }}>
            <Title>{'OOK.'}</Title>
            <TextSection>
              <NounText>{'noun'}</NounText>
              <PhoneticText>{'/ u:k/'}</PhoneticText>
            </TextSection>
            <TextSection>
              {'game & film scoring by Patrick Moriarty'}
            </TextSection>
            <ButtonSection>
              <Button accent onClick={playShowreel}>Showreel <ButtonIcon component={Play} marginLeft /></Button>
              <Button onClick={() => navigate('/about#contact')}>Contact Me <ButtonIcon component={Smile} marginLeft /></Button>
            </ButtonSection>
            <SocialButtons />
          </div>
          <AlbumCover id="hero-album" href="https://thisisook.bandcamp.com/album/janus-ost" stacked imgUrl={JanusOstArtwork} style={{ marginLeft: '1rem' }}/>
        </HeroContent>
        <NavContainer>{children}</NavContainer>
      </HeroContainer>
    </MainContainer>
  );
}

Hero.propTypes = {};
Hero.defaultProps = {};

export const SocialButtons = ({ style }) => (
  <ButtonSection {...(style || {})}>
    <SocialIcon href="https://thisisook.bandcamp.com" label="Bandcamp" size={1.4} url={BandcampIcon} />
    {/* <SocialIcon label="Youtube" size={1.8} url={YoutubeIcon} /> */}
    <SocialIcon href="https://twitter.com/thisisook" label="Twitter" size={1.5} url={TwitterIcon} />
    <SocialIcon href="mailto:patrick@ook.fm" label="Email" size={1.5} url={MailIcon} />
  </ButtonSection>
)

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HeroContainer = styled.div`
  position: relative;
  height: 35rem;
  width: 100%;
  max-width: 70rem;
`;

const stripeWidth = 9;
const HeroSlant = styled.div`
  position: absolute;
  top: -100%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: repeating-linear-gradient(90deg, #FBFBFB, #FBFBFB ${stripeWidth}rem, #F1F1F1 ${stripeWidth}rem, #F1F1F1 ${stripeWidth * 2}rem);
  transform: rotate(-7deg);
  position: absolute;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
`;

const NavContainer = styled.div`
  position: absolute;
  top: 0;
`;

const HeroContent = styled.div`
  padding: 10% 5rem 5rem;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 650px) {
    & #hero-album {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 10% 2rem 5rem;
  }
`;

const Title = styled.h1`
  padding: 0;
  margin: 0;
  padding-bottom: 1rem;
  font-family: Amatic SC, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 5rem;
  line-height: 5rem;
  letter-spacing: 0.035em;
  color: black;
`;

const TextSection = styled.div`
  padding: 0;
  margin: 0;
  padding-bottom: .75rem;
`;

const ButtonSection = styled(TextSection)`
  padding-top: .5rem;
  padding-bottom: 0;

  & button {
    margin: 0 .75rem .75rem 0;
  }
`;

const Text = styled.span`
  font-size: 1.125rem;
  letter-spacing: 0.035em;
  font-style: normal;
`;

const NounText = styled(Text)`
  font-weight: bold;
  padding-right: 1rem;
`;

const PhoneticText = styled(Text)`
  color: #797979;
`;

const ButtonIcon = ({ component, size, marginLeft, style, ...props }) => {
  let Component = component;

  return (
    <Component style={{ 
        ...(marginLeft ? { marginLeft: '1rem' } : {}), 
        width: size ?? '1rem',
        height: size ?? '1rem', 
        ...(style ?? {}) }} 
      {...props} />
  );
};

const SocialIcon = ({ url, size, label, ...props }) => {
  return (
    <IconAnchor size={size} color="black" {...props}>
      <SocialIconImg aria-label={label} alt={label} src={url} size={size} />
    </IconAnchor>
  )
}

const SocialIconImg = styled.img`
  width: ${props => props.size + 'rem'};
  height: ${props => props.size + 'rem'};
  object-fit: contain;

  @media only screen and (max-width: 400px) {
    width: ${props => (props.size * 1.3) + 'rem'};
    height: ${props => (props.size * 1.3) + 'rem'};
  }
`;

const IconLinkCreator = fn => fn`
  text-decoration: none;
  user-select: none;
  color: ${props => props.color};
  cursor: pointer;
  margin-right: 1rem;
  width: ${props => props.size ?? '1.5rem'};
  height: ${props => props.size ?? '1.5rem'};

  & * {
    transition: transform 100ms;
  }

  &:hover * {
    transform: translateY(-0.25rem);
  }

  @media only screen and (max-width: 400px) {
    width: ${props => (props.size * 1.3) ?? '1.5rem'};
    height: ${props => (props.size * 1.3) ?? '1.5rem'};
  }
`;

const IconLink = IconLinkCreator(styled(Link));
const IconAnchor = IconLinkCreator(styled.a);

export default Hero;